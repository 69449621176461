<template>
  <v-container class="p-0" fluid>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-2"
      >
        <v-row>
          <v-col cols="1" md="1" class="pt-2 pb-0">
            <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
          </v-col>
          <v-col cols="11" md="11" class="py-0">
            <PageHeader :title="title" />
          </v-col>
        </v-row>
        <v-form
          v-model="isFormValid"
          ref="filter-traductor-codigo-nomenclador"
          id="filter-traductor-codigo-nomenclador"
          @submit.prevent="applyFilters()"
        >
          <v-row>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                label="Código de Origen"
                v-model.trim="codigoOrigen"
                dense
                v-mask="'##########'"
                @change="findOrigenById()"
                hide-details="auto"
                :loading="loadingOrigenById"
                outlined
                id="codigo-origen-traductor"
              >
                <template v-slot:append-outer>
                  <v-tooltip top max-width="50%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        id="btn-origen-traductor"
                        v-on="on"
                        tabindex="-1"
                        @click="toggleModalBusquedaOri()"
                      >
                        {{ searchIcon }}
                      </v-icon>
                    </template>
                    <span>Buscar Origen por nombre, CUIT o código</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <!-- DATOS ORIGEN -->

            <v-col cols="12" md="5" class="p-0 pl-1 ">
              <v-text-field
                type="text"
                outlined
                dense
                filled
                disabled
                :rules="rules.required"
                readonly
                label="Origen"
                hide-details="auto"
                v-model.trim="origenSelectedNom"
                autocomplete="off"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0 pt-2 text-right">
              <v-btn
                @click="applyFilters()"
                small
                :disabled="!isFormValid"
                type="submit"
                form="filter-traductor-codigo-nomenclador"
                color="primary"
                id="btn-cargar-traductor"
                >Aplicar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-data-table
                dense
                :headers="headers"
                :items="registros"
                :search="search"
                hide-default-header
                item-key="origenTraId"
                class="custom-table"
                :loading="loadingTraductorCodigoNomenclador"
                :expanded.sync="expanded"
              >
                <template v-slot:top>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" class="py-1 pb-2 pl-8">
                        <v-text-field
                          v-model="search"
                          :append-icon="searchIcon"
                          label="Buscar"
                          dense
                          id="search-traductor-codigo-nomenclador"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" md="3" class="text-right pt-2 pb-0">
                        <v-btn
                          @click="goToProcesosImportacion()"
                          small
                          id="btn-exportar-traductor"
                          hide-details
                          color="primary"
                          outlined
                          >Procesos de importación</v-btn
                        >
                      </v-col>
                      <v-col cols="3" md="2" class="text-right pt-2 pb-0">
                        <v-btn
                          @click="exportarExcel()"
                          small
                          id="btn-exportar-traductor"
                          hide-details
                          :loading="loadingExportarExcel"
                          :disabled="
                            loadingExportarExcel || registros.length === 0
                          "
                          color="primary"
                          outlined
                          >Exportar a Excel</v-btn
                        >
                      </v-col>
                      <v-col cols="3" md="1" class="text-right pt-2 pb-0">
                        <v-btn
                          @click="toggleModalNuevoTraductor()"
                          small
                          :disabled="!canCreate || oriId == null"
                          hide-details
                          id="btn-nuevo-traductor"
                          color="primary"
                          >Nuevo</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <template v-slot:header="{ props }">
                  <thead class="p-0">
                    <tr class="p-0">
                      <th colspan="2" class="py-0">Datos de Gecros</th>
                      <th
                        colspan="2"
                        class="py-0"
                        style="border-left: 1px solid #939393"
                      >
                        Datos del Origen
                      </th>
                    </tr>
                    <tr>
                      <th
                        v-for="header in props.headers"
                        :key="header.text"
                        :class="{
                          'with-divider': header.value === 'codigoPractica'
                        }"
                      >
                        {{ header.text }}
                      </th>
                    </tr>
                  </thead>
                </template>

                <template v-slot:[`item`]="{ item, isExpanded }">
                  <tr>
                    <td>{{ item.nomCod }}</td>
                    <td class="nombre-practica-item">{{ item.nombre }}</td>
                    <td class="with-divider">{{ item.codigoPractica }}</td>
                    <td class="nombre-practica-item">
                      {{ item.nombrePractica }}
                    </td>
                    <!-- Columna de acciones -->
                    <td>
                      <v-row dense justify="center" align="center" class="pa-0">
                        <v-col cols="auto" class="pa-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                @click="verRegistro(item)"
                              >
                                {{ seeIcon }}
                              </v-icon>
                            </template>
                            <span>Ver</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="auto" class="pa-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                small
                                v-on="on"
                                @click="editarRegistro(item)"
                              >
                                {{ editIcon }}
                              </v-icon>
                            </template>
                            <span>Editar</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="auto" class="pa-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="eliminarRegistro(item.origenTraId)"
                              >
                                {{ deleteIcon }}
                              </v-icon>
                            </template>
                            <span>Eliminar</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="auto" class="pa-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="handleExpansion(item, isExpanded)"
                                v-bind="attrs"
                                small
                                v-on="on"
                              >
                                {{
                                  isExpanded
                                    ? "mdi-account-check"
                                    : "mdi-account"
                                }}
                              </v-icon>
                            </template>
                            <span>Usuarios de carga y modificación</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-row class="fondoExpandable">
                      <v-col cols="12" class="py-0 pl-2">
                        <strong class="expandableInfoTitle"
                          >Fecha alta:
                        </strong>
                        <span class="expandableInfoText">{{
                          item.fecAlta
                        }}</span>
                        <strong class="expandableInfoTitle"
                          >Usuario alta:
                        </strong>
                        <span class="expandableInfoText">{{
                          item.usuAlta
                        }}</span>
                        <strong class="expandableInfoTitle"
                          >Fecha modificación:
                        </strong>
                        <span class="expandableInfoText">{{
                          !item.fecModi ? "Sin datos." : item.fecModi
                        }}</span>
                        <strong class="expandableInfoTitle"
                          >Usuario modificación:
                        </strong>
                        <span class="expandableInfoText">{{
                          item.usuModi != null ? item.usuModi : "Sin datos."
                        }}</span>
                      </v-col>
                    </v-row>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-0 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :isLoading="loadingBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
    <v-dialog
      v-model="modalNuevoTraductor"
      v-if="modalNuevoTraductor"
      @keydown.esc="toggleModalNuevoTraductor"
      max-width="55rem"
      persistent
      this.disableEdit="false"
    >
      <NuevoTraductorCodigoNomenclador
        @toggleModalNuevoTraductor="toggleModalNuevoTraductor"
        @closeAndReload="closeAndReload"
        :oriId="oriId"
        :origenTraItem="origenTraItem"
        :disableEdit="disableEdit"
      ></NuevoTraductorCodigoNomenclador>
    </v-dialog>
    <v-dialog
      v-model="modalBusquedaOrigen"
      v-if="modalBusquedaOrigen"
      @keydown.esc="toggleModalBusquedaOri"
      max-width="60rem"
      persistent
    >
      <BusquedaOrigenes
        @toggleModalBusquedaOri="toggleModalBusquedaOri"
        @findOrigen="findOrigen"
      ></BusquedaOrigenes>
    </v-dialog>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import PageHeader from "@/components/ui/PageHeader";
import Ayuda from "@/components/shared/Ayuda.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import BusquedaOrigenes from "@/components/modules/prestadores/BusquedaOrigenes.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import NuevoTraductorCodigoNomenclador from "@/components/modules/prestadores/NuevoTraductorCodigoNomenclador.vue";

export default {
  name: "TraductorCodigosNomenclador",
  components: {
    PageHeader,
    GoBackBtn,
    BusquedaOrigenes,
    DeleteDialog,
    NuevoTraductorCodigoNomenclador,
    Ayuda
  },
  directives: { mask },
  data() {
    return {
      optionCode: enums.webSiteOptions.TRADUCTOR_CODIGOS,
      searchIcon: enums.icons.SEARCH,
      seeIcon: enums.icons.SEE,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      allowedActions: [],
      canDelete: false,
      canCreate: false,
      canEdit: false,
      routeToGo: "ConfiguracionPrestadores",
      title: "Traductor de códigos de prácticas",
      titleDelete: "¿Desea eliminar el registro?",
      loadingBtnDelete: false,
      loadingExportarExcel: false,
      showDeleteModal: false,
      idToDelete: null,
      modalBusquedaOrigen: false,
      modalNuevoTraductor: false,
      disableEdit: false,
      showHelp: false,
      showExpand: false,
      showIcon: true,
      rules: rules,
      origenSelectedNom: null,
      oriId: null,
      origenTraItem: null,
      origenSelectedCuit: null,
      codigoOrigen: "",
      isFormValid: false,
      loadingOrigenById: false,
      loadingTraductorCodigoNomenclador: false,
      registros: [],
      expanded: [],

      search: null,
      headers: [
        {
          text: "Código práctica ",
          value: "nomCod",
          align: "start"
        },
        { text: "Nombre de práctica ", value: "nombre", align: "start" },

        // {
        //   text: "Código tipo nomenclador",
        //   value: "codigoTipoNomenclador"
        // },
        // {
        //   text: "Nombre tipo nomenclador",
        //   value: "nombreTipoNomenclador"
        // },
        { text: "Código práctica", align: "start", value: "codigoPractica" },
        { text: "Nombre de práctica", align: "start", value: "nombrePractica" },
        // { text: "Área", value: "area" },
        // { text: "Tipo nomenclador", value: "tipoNom" },
        { text: "Acciones", value: "actions", align: "end", sortable: false }
      ]
    };
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    if (this.$route.params != null && this.$route.params.oriId) {
      this.findOrigen(this.$route.params);
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getOrigenById: "prestadores/getOrigenById",
      deleteOrigenTraductor: "prestadores/deleteOrigenTraductor",
      exportarExcelOrigenTraductor: "prestadores/exportarExcelOrigenTraductor",
      getTraductorCodigoNomencladorPorOrigen:
        "prestadores/getTraductorCodigoNomencladorPorOrigen"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_EDITAR_ORIGEN_TRADUCTOR:
            this.canCreate = true;
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_ORIGEN_TRADUCTOR:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async applyFilters() {
      try {
        this.loadingTraductorCodigoNomenclador = true;
        const response = await this.getTraductorCodigoNomencladorPorOrigen(
          this.oriId
        );
        this.registros = response;
      } catch (error) {
      } finally {
        this.loadingTraductorCodigoNomenclador = false;
      }
    },
    closeAndReload() {
      this.modalNuevoTraductor = false;
      this.applyFilters();
    },

    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    editarRegistro(item) {
      this.toggleModalNuevoTraductor(item);
    },
    verRegistro(item) {
      this.disableEdit = true;
      this.toggleModalNuevoTraductor(item);
    },
    eliminarRegistro(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      this.loadingBtnDelete = true;

      try {
        const response = await this.deleteOrigenTraductor(this.idToDelete);
        if (response.status === 200) {
          this.setAlert({
            message: "Registro eliminado correctamente",
            type: "success"
          });
          this.applyFilters();
        }
      } catch (error) {
      } finally {
        this.loadingBtnDelete = false;
        this.showDeleteModal = false;
      }
    },
    goToProcesosImportacion() {
      this.$router.push({
        name: "ProcesosImportacionTraductorCodigoNomenclador",
        params: this.oriId
          ? {
              nombre: this.origenSelectedNom,
              oriId: this.oriId,
              cuit: this.origenSelectedCuit
            }
          : null
      });
    },
    async exportarExcel() {
      if (this.oriId == null) {
        this.setAlert({
          message: "Seleccione un origen para continuar",
          type: "warning"
        });
        return;
      }
      this.loadingExportarExcel = true;
      try {
        const data = {
          oriId: this.oriId,
          procId: null
        };
        const response = await this.exportarExcelOrigenTraductor(data);
        if (response.status === 200) {
          this.setAlert({
            message: "Exportación realizada correctamente",
            type: "success"
          });
        }
      } catch (error) {
      } finally {
        this.loadingExportarExcel = false;
      }
    },

    async findOrigenById() {
      if (!this.codigoOrigen) return;
      try {
        this.loadingOrigenById = true;
        const origenResponse = await this.getOrigenById(this.codigoOrigen);
        if (origenResponse != null) {
          this.findOrigen(origenResponse);
        } else {
          this.origenSelectedNom = null;
          this.codigoOrigen = null;
          this.oriId = null;
          this.origenSelectedCuit = null;
          this.setAlert({
            message: "No se encontró el origen",
            type: "error"
          });
        }
      } catch (error) {
      } finally {
        this.loadingOrigenById = false;
      }
    },
    findOrigen(origenSelected) {
      this.origenSelectedNom = origenSelected.nombre;
      this.codigoOrigen = origenSelected.oriId;
      this.oriId = origenSelected.oriId;
      this.origenSelectedCuit = origenSelected.cuit;
    },
    toggleModalBusquedaOri() {
      this.modalBusquedaOrigen = !this.modalBusquedaOrigen;
    },
    toggleModalNuevoTraductor(item) {
      if (this.oriId == null) {
        this.setAlert({
          message: "Seleccione un origen para continuar",
          type: "warning"
        });
        return;
      }
      this.origenTraItem = item;
      this.modalNuevoTraductor = !this.modalNuevoTraductor;
      if (!this.modalNuevoTraductor) {
        this.disableEdit = false;
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
.with-divider {
  border-left: 1px solid #939393;
  padding-left: 10px;
}
.expandableInfoTitle {
  font-size: 0.8rem;
  padding-left: 1rem;
}
.expandableInfoText {
  font-size: 0.7rem;
}
.fondoExpandable {
  background-color: #0000000c;
}
.nombre-practica-item {
  font-size: 0.6rem !important;
}
</style>
